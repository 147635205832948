import React from 'react';
import { Link } from 'react-router-dom';
import ftbgimg from "../assets/images/FTBG.jpg";
export default function Footer() {
    return (
        <section className="ftbg" style={{ backgroundImage: `url(${ftbgimg})` }}>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                            <h5 className="text-uppercase">About cloud e desk</h5>
                            <p>Cloud e desk is a pioneering Digital Media Creation, Management, & Marketing agency specialized in delivering website designing, development, and digital marketing services.</p>
                            <p>Our team is geared to delivering More in every aspect of our business. More responsiveness, More Reliability, & More Results. We believe in excellence in execution of every task, whether it is a small assignment, or a large global campaign. <Link to="/About"> more... </Link></p>
                        </div>
                        <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
                            <h5 className="text-uppercase">Our Services</h5>
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <Link to="/Marketing">
                                        Marketing
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Development">
                                        Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/WebsiteDesign">
                                        Web Design
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/SearchEngineOptimization">
                                        SEO Optimization
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/Termsofuse">
                                    Terms of Use
                                    </Link>
                                </li>
                                
                                <li>
                                    <Link to="/PrivacyPolicy">
                                    Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/MyServices">
                                        View All Services
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                            <h5 className="text-uppercase faddress">Contact Us</h5>
                            <p>124-128 City Rd, London EC1V 2NJ<br /> United Kingdom</p>
                            <p>info@cloudedesk.com<br /><a href='tel:+44 0203 930 0929'>+44 0203 930 0929</a></p>
                            
                        </div>
                    </div>
                </div>
                <div className="text-center p-3"> © 2024 Copyright:  Cloud e desk</div>
            </footer>
        </section>

    )
}
