import React from 'react'
import Branding from "../assets/images/service_icon_6-1.png";
export default function BrandingWeb() {
    return (
        <>
            <div className="container myservices">

                <div className="row">
                    <div className="col-sm-1"> <img src={Branding} alt="Branding" /></div>
                    <div className="col-sm-11">
                        <h3>Branding</h3>
                        <p>Your brand is more than just a logo, name or color scheme. It’s who you are as a company at a very fundamental level.</p>
                        <p>Branding dictates how customers feel about your business and, when done right, can elicit an emotional response at every touch point. Businesses with strong brands retain loyal customers for the long run and have an easier time appealing to new audiences because they already know what those companies stand for.</p>
                        <p>This is all to say that if you aren’t effectively branding your business online and elsewhere, you’re putting some major barriers in the way of your marketing efforts.</p>
                        <p>Branding services can help companies with any aspect they might be struggling with, whether they need to create a brand identity from scratch or simply find a better way to communicate their core messaging and company values.</p>
                    </div>

                </div>
            </div>
        </>
    )
}
