import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import logo from "../assets/images/cloudedesk.png";
import Carousel from 'react-bootstrap/Carousel';
import Bannerslide1 from "../assets/images/Bannerslide1.jpg";
import Bannerslide2 from "../assets/images/Bannerslide2.jpg";
import Bannerslide3 from "../assets/images/Bannerslide3.jpg";
import Bannerslide4 from "../assets/images/Bannerslide4.jpg";
export default function Header() {

  return (
    <>
      <div className="container-fluid BGBanner">

        <header>
          <Navbar collapseOnSelect expand="lg" className="navbg">
            <Container>
              <Navbar.Brand><Link to="/"><img src={logo} alt="Logo" /></Link></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link><Link to="/">Home</Link></Nav.Link>

                  <div className="dropdown">
                    <Nav.Link><Link to="/MobileUIUX" className="dropbtn">MOBILE APPS <i className="fas fa-caret-down"></i></Link></Nav.Link>
                    <div className="dropdown-content">
                      <Nav.Link><Link to="/MobileUIUX">Mobile UI/UX Designing </Link></Nav.Link>
                      <Nav.Link><Link to="/AndroidAppDevelopment">Android App Development</Link></Nav.Link>
                      <Nav.Link><Link to="/IOSAppDevelopment">IOS App Development</Link></Nav.Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Nav.Link><Link to="/Development" className="dropbtn">WEB DEVELOPMENT <i className="fas fa-caret-down"></i></Link></Nav.Link>
                    <div className="dropdown-content">
                      <Nav.Link><Link to="/Development">Website Development </Link></Nav.Link>
                      <Nav.Link><Link to="/WebsiteMaintenance">Website Maintenance</Link></Nav.Link>
                      <Nav.Link><Link to="/WebsiteHosting">Website Hosting</Link></Nav.Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Nav.Link><Link to="/WebsiteDesign" className="dropbtn">WEB DESIGN <i className="fas fa-caret-down"></i></Link></Nav.Link>
                    <div className="dropdown-content">
                      <Nav.Link><Link to="/WebsiteDesign">Website Design </Link></Nav.Link>
                      <Nav.Link><Link to="/WebsiteDesignCompany">Website Design Company</Link></Nav.Link>
                      <Nav.Link><Link to="/UIUXDesign">UI/UX Design</Link></Nav.Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Nav.Link><Link to="/Ecommerce" className="dropbtn">ECOMMERCE <i className="fas fa-caret-down"></i></Link></Nav.Link>
                    <div className="dropdown-content">
                      <Nav.Link><Link to="/WordpressWebsite">Wordpress Website Development </Link></Nav.Link>
                      <Nav.Link><Link to="/ShopifyWebsite">Shopify Website Development</Link></Nav.Link>
                      <Nav.Link><Link to="/BigcommerceWebsite">Bigcommerce Website Development</Link></Nav.Link>
                    </div>
                  </div>

                  <Nav.Link><Link to="/About">About Us</Link></Nav.Link>
                  <Nav.Link><Link to="/Contact">Contact Us</Link></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

        </header>
      </div>
      <div className="container-fluid sliderheader">
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={Bannerslide1} alt="BUSINESS" />
            <Carousel.Caption>
              <div className="col-sm-12">
                <p className="wer">Accelerate your journey to a digital enterprise.</p>
                <h1>GROW YOUR <span>BUSINESS</span> WITH US</h1>
                <p>With A Professional & Responsive Website. With the Experience of designing 250+ websites<br />and extensive expertise in this field, our team is optimized to design<br />amazing websites for your businesses.</p>
               
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Bannerslide2} alt="WE ARE CREATIVE" />
            <Carousel.Caption>
              <div className="col-sm-12">
                <p className="wer">WE ARE CREATIVE</p>
                <h1>100%  <span> satisfaction </span>  hosting </h1>
                <p>Discover our new offer and enjoy modern hosting today! </p>
               
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Bannerslide3} alt="DIGITAL" />
            <Carousel.Caption>
              <div className="col-sm-12">
                <p className="wer">WE ARE CREATIVE</p>
                <h1>What is  <span>web  </span> development?</h1>
                <p>To begin with, the term web development is as vast as the ocean. There are innumerable <br />programming languages as well as scores of frameworks and tools that are<br /> the lifeline of technical web development.</p>
            
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Bannerslide4} alt="UI AND UX" />
            <Carousel.Caption>
              <div className="col-sm-12">
                <p className="wer">To acquire, retain, and delight your clients</p>
                <h1>UI AND UX <span>DESIGN</span> SERVICES</h1>
                <p>With A Professional & Responsive Website. With the Experience of designing 250+ websites<br />and extensive expertise in this field, our team is optimized to design<br />amazing websites for your businesses.</p>
              
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <div className="creative-box1"></div>
      </div>
    </>

  )
}