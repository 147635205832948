import React from 'react';
import UIUXDesign from "../assets/images/UIUXDesign.jpg";
export default function MobileUIUX() {
    return (
        <>
            <div className="container myservices">
                <div className="row">
                    <div className="col-sm-7">
                        <h3>Mobile UI/UX Designing</h3>
                        <p>Bet it established or start-up businesses, every segment is now incorporating highly customized user interface designs in their mobile applications.
                            A creative and well-coded UI/UX design helps in increasing the affordability, usability and essential worth of their services and products.
                            UI/UX design is one of the key elements that have the capability to be the reason of difference between user’s satisfaction and user apprehension.
                            This is why we have a team of android mobile app designers who are very particular about the details.</p>
                    </div>
                    <div className="col-sm-5"> <img src={UIUXDesign} alt="UI UX Design" className="img-thumbnail" /></div>
                </div>
            </div>
        </>
    )
}
