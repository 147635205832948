import React from 'react';
import WebHosting from "../assets/images/WebHosting.jpg";
export default function WebsiteHosting() {
    return (
        <>
        <div className="container myservices">
            <div className="row">
                <div className="col-sm-7">
                    <h3>Website Hosting</h3>
                   <p>Cloud e desk has many web hosting packages to suit your requirements to host your website.</p>
                  <p>We host sites using both Window based and Linux based systems.
We provide a range of highly reliable web hosting company in Delhi services according to client’s requirement.
</p>
 <p>Web Hosting is not just about uploading of files on the web server but it includes following technical elements to suit your unique needs and requirements.</p>
                    </div>
                <div className="col-sm-5"> <img src={WebHosting} alt="About Us" className="img-thumbnail" /></div>
            </div>
        </div>
    </>
    )
}
