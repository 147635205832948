import React from 'react'
import UIUXDesign from "../assets/images/UIUXDesign.jpg";
export default function UIUXDesignw() {
    return (
        <>
        <div className="container myservices">
            <div className="row">
                <div className="col-sm-7">
                    <h3>UI/UX Design</h3>
                    <p>“Design” is a broad term which is associated with number of responsibilities. In the tech space, design mainly refers to web or graphic designing. With new technologies coming every day, best UI UX designing companies in India share the responsibility in this new way of web designing. UI and UX Design is not merely limited to the look of a website rather it focuses on the ease of use and influence branding of a company. Both of them complement each other because one helps you to have great design and the latter is responsible for its functional aspects. Cloud e desk, a leading website designing company in Delhi is ready to assist you for the same. UX Design stands for User experience design while UI means user interface design. UXdesign is analytical and technical; UI design is closer to graphic design. The user interface can be understood as way of how a user interacts while user experience is about how user feels while using the gadget. Though they seem similar yet have different functionality, however both contribute to a strong web presence.</p>
                    </div>
                <div className="col-sm-5"> <img src={UIUXDesign} alt="UIUXDesign" className="img-thumbnail" /></div>
            </div>
        </div>
    </>
    )
}
