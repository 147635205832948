import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import contactbanner from "../assets/images/contact_banner.png";
export default function Contact() {
    return (
        <div className="container contactus">
            <div className="row">
                <div className="col-sm-12">
                    <h2>Contact Us</h2>
                    <p>You don't always need a plan. Sometimes all you need to do is<br />Sit back, Relax and Trust.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    <div className="padding-50 contme">
                        <i class="fas fa-map"></i>
                        <h3>Address</h3>
                        <p>124-128 City Rd, London EC1V 2NJ, United Kingdom</p>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="padding-50">
                        <i class="fas fa-envelope"></i>
                        <h3>Email</h3>
                        <p>info@cloudedesk.com  </p>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="padding-50">
                        <i class="fas fa-phone"></i>
                        <h3>Phone</h3>
                        <p><a href='tel:+44 0203 930 0929'>+44 0203 930 0929</a> </p>
                    </div>
                </div>

            </div>
            <div className="row contectfr">            
            <div className="col-sm-6">
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="Name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" />
                        </Form.Group></Row>
                        <Row className="mb-3">
                        <Form.Group as={Col} controlId="Email">
                            <Form.Label>Email ID</Form.Label>
                            <Form.Control type="text" placeholder="Enter email" />
                        </Form.Group></Row>
                        <Row className="mb-3">
                        <Form.Group as={Col} controlId="contactno">
                            <Form.Label>Contact No</Form.Label>
                            <Form.Control type="text" placeholder="Enter contact no" />
                        </Form.Group>

                    </Row>

                    <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label>Your message</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>


                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                </div>
                <div className="col-sm-6"><img src={contactbanner} alt="BigCommerce" className="img-thumbnail" /></div>
            </div>
        </div>
    )
}
