import React from 'react';
import maintenance from "../assets/images/web_maintenance.jpg";
export default function WebsiteMaintenance() {
    return (
        <>
            <div className="container myservices">
                <div className="row">
                    <div className="col-sm-7">
                        <h3>Web Maintenance </h3>
                        <p>We believe that making a web site is not a onetime affair; it's a lifelong commitment...<br />
                            At Cloud e desk (Web Maintenance Services), we don't treat making a web site as a single isolated assignment, but rather a lifelong commitment. A web site is the critical first impression of your company for your visitors. It should be fresh and dynamic at all times. Once you have decided to have your online presence, do it in a professional way. Take assistance of a reliable website designing company in Delhi to create a website and maintain it for you as and when you need.
                        </p>
                        <p>A website needs to be treated in the same way that anyone takes care of their business, health, house or car. Launching a website is not the only task the next step would be its maintenance, and if ignored then your website is only nearing towards inactivity. After all, what is the use of a website that is not functioning well. The actual success of your online website or business presence will depend a lot on how well you are able to maintain it.</p>
                        <p>Our team has years of experience in website maintenance. Before you go for web maintenance services, it is essential to know about advantages of maintaining your website. We can help you with any type of Website Maintenance services required, Cloud e desk Interactive can help you with your website management needs and make your business grow successfully. With web technology continually changing, you just cannot keep your online presence immobile.</p>
                    </div>
                    <div className="col-sm-5"> <img src={maintenance} alt="web maintenance" className="img-thumbnail" /></div>
                </div>
            </div>
        </>
    )
}
