import React from 'react';
import Shopify from "../assets/images/Shopify.jpg";
export default function ShopifyWebsite() {
    return (
        <>
        <div className="container myservices">
            <div className="row">
                <div className="col-sm-7">
                    <h3>Shopify Website Development</h3>
                    <p>Gone are the days when you had to work harder to establish your traditional business! The solutions available in tech world today have paved the way for success. Emerged as a web application, Shopify has become a dynamic e-commerce solution provider! It contains all the imperative features and tools to enable selling anything from goods to services. A boon for online retail businesses, shopify serves as the best platform nowadays. Shopify website development services in Delhi NCR, India are preferred by many business owners because of the good speed and security features. As a business owner, you can manage the security with integration among the payment providers. It applies all the distinctive features of this online platform and makes your site perform at its best. We understand the fact that it is the smarter way to promote your business and hence we bring you the best website development services. Therefore, when it comes to website designing company in Delhi, you can rely on us.</p>
                </div>
                <div className="col-sm-5"> <img src={Shopify} alt="About Us" className="img-thumbnail" /></div>
            </div>
        </div>
    </>
    )
}
