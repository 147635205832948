import React from 'react';
import WebsiteDesign from "../assets/images/Website-Design.jpg";
export default function WebsiteDesignw() {
    return (
        <>
        <div className="container myservices">
            <div className="row">
                <div className="col-sm-7">
                    <h3>Website Design</h3>
                   <p>If your business aims at reaching potential customers faster, you need a website! Web design company in Delhi, India play a significant role when it comes to your business growth beyond geographical boundaries. Hiring the services of a professional web design company enables you to develop an impressive website. It also takes care of the promotion of your website besides making it user friendly.</p>
<p>Starting with logo design, graphic design, web hosting, today web designing services can extend up to multimedia presentation, video shoots, flash design and even online marketing and many others. Following a professional approach is needed here because every project involves a number of complexities at every step. Cloud e desk, a leading website designing company in Delhi can assist you to overcome these hurdles and brings you hassle-free website design services. If you aspire to operate your business on web, get a website created by a professional company. The best benefit of taking professional help for web designing is that it enables you to take your brand global which you may not be able to.</p>
<p>Believe in our web design service team to create a unique, user-friendly and powerful web design that represents your company image in the best possible light. Cloud e desk Interactive web design company delivers great results ensuring your brand gets connected with your clients. A world-class website design will make your company more competitive online and help your customers find out the information they need about your organization. As a top web designing company in india, we work with clients from different industry segments. It is always our goal to create a web design with a perfect balance of form and function. With our web design service Delhi, your brand will get noticed in no time! We are a web design company that will ensure your brand gets noticed.</p>

                   </div>
                <div className="col-sm-5"> <img src={WebsiteDesign} alt="Website Design" className="img-thumbnail" /></div>
            </div>
        </div>
    </>
    )
}
