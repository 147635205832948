import React from 'react';
import { Link } from 'react-router-dom';
import Marketing from "../assets/images/service_icon_1-1.png";
import Development from "../assets/images/service_icon_2-1.png";
import WebDesign from "../assets/images/service_icon_3-1.png";
import SEOOptimization from "../assets/images/service_icon_4-1.png";
import Ecommerce from "../assets/images/service_icon_5-1.png";
import Branding from "../assets/images/service_icon_6-1.png";

export default function MyServices() {
    return (
        <>
        <div className="container myweb">
            <div className="row">
                <div className="col-sm-12">
                    <h2>We are the one of the most effective<br />Web Design Companies</h2>
                    <p>Getting online is easy. Succeeding online is a different story. You’ll need more than just a beautiful<br />website to stand out these days. Online marketing solutions. Conversion-based web design<br /> coupled with a lead generating marketing plan, your online success is inevitable.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                <div className="padding-50">
                <Link to="/Marketing">
                    <img src={Marketing} alt="Marketing" />
                    <h3>Marketing</h3>
                    <p>We use strategic marketing tactics that have been proven to work.</p>
                    </Link>
                </div>
                </div>
                <div className="col-sm-4">
                <div className="padding-50">
                <Link to="/Development">
                    <img src={Development} alt="Development" />
                    <h3>Development</h3>
                    <p>Custom programming for most complex functions you can think.</p>
                    </Link>
                </div>
                </div>
                <div className="col-sm-4">
                <div className="padding-50">
                <Link to="/WebsiteDesign">
                    <img src={WebDesign} alt="Web Design" />
                    <h3>Web Design</h3>
                    <p>Pork chop pork belly hamburger prosciutto, fatback andouille flank.</p>
                    </Link>
                </div>
                </div>
                <div className="col-sm-4">
                <div className="padding-50">
                <Link to="/SearchEngineOptimization">
                    <img src={SEOOptimization} alt="SEO Optimization" />
                    <h3>SEO Optimization</h3>
                    <p>Optimizing our web designs to rank on the first page of google is our specialty.</p>
                    </Link>
                </div>
                </div>
                <div className="col-sm-4">
                <div className="padding-50">
                <Link to="/Ecommerce">
                    <img src={Ecommerce} alt="Ecommerce" />
                    <h3>Ecommerce</h3>
                    <p>We build your online store using a flexible, modular platform that allows</p>
                    </Link>
                </div>
                </div>
                <div className="col-sm-4">
                <div className="padding-50">
                <Link to="/Branding">
                    <img src={Branding} alt="Branding" />
                    <h3>Branding</h3>
                    <p>A solid brand strategy, logo and guidelines help you to get You recognized.</p>
                    </Link>
                </div>
                </div>
            </div>           
        </div>   
        </>
    )
}
