import React from 'react';
import WordPress from "../assets/images/WordPress.jpg";
export default function WordpressWebsite() {
    return (
        <>
            <div className="container myservices">
                <div className="row">
                    <div className="col-sm-7">
                        <h3>Wordpress Website Development</h3>
                        <p>Being a complete and all-inclusive website, WordPress website is gaining popularity. Be it a simple brochure website or an e-commerce solution with an integrated forum, WordPress website designing and development companies are able to create the ultimate solution to meet your marketing objectives. Even if you are looking for something unique, they can create a customized solution, designed specifically to suit your project needs. Being a preferred website designing company in Delhi, Cloud e desk is fully equipped to take over a number of projects. If you need to get a Wordpress website developed, our professional team is here to assist you at every step. WordPress has easy to use architecture, numerous features, templates and customization that make it highly preferred by clients across the globe.</p>

                        <p>WordPress website development projects follow a structured process aiding in executing the project within budget and time constraints. Creating a WordPress website ensures attracting search engines and encourages users to convert into leads and revenue.</p>

                    </div>
                    <div className="col-sm-5"> <img src={WordPress} alt="WordPress" className="img-thumbnail" /></div>
                </div>
            </div>
        </>
    )
}
