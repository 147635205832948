import './App.css';
import './deep.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Company/Header';
import Footer from './Company/Footer';
import Home from './Company/Home';
import About from './Company/About';
import Contact from './Company/Contact';
import MyServices from './AllServices/MyServices';
import WebsiteDesign from './AllServices/WebsiteDesign';
import Marketing from './AllServices/Marketing';
import Development from './AllServices/Development';
import SearchEngineOptimization from './AllServices/SearchEngineOptimization';
import AndroidAppDevelopment from './AllServices/AndroidAppDevelopment';
import BigcommerceWebsite from './AllServices/BigcommerceWebsite';
import IOSAppDevelopment from './AllServices/IOSAppDevelopment';
import MobileUIUX from './AllServices/Mobile-UI-UX';
import ShopifyWebsite from './AllServices/ShopifyWebsite';
import UIUXDesign from './AllServices/UI-UX-Design';
import WebsiteDesignCompany from './AllServices/WebsiteDesignCompany';
import WebsiteHosting from './AllServices/WebsiteHosting';
import WebsiteMaintenance from './AllServices/WebsiteMaintenance';
import WordpressWebsite from './AllServices/WordpressWebsite';
import Ecommerce from './AllServices/Ecommerce';
import Branding from './AllServices/Branding';
import PrivacyPolicy from './Company/PrivacyPolicy';
import Termsofuse from './Company/Termsofuse';
import Error from './Error';
 
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
       <Router>
      <Header />
      <Switch>
        <Route exact path="/" render={() => {
          return (
            <>
             <Home />
            </>
          )
        }}>
        </Route>
          
        <Route exact path="/About">
          <About />
        </Route>
        <Route exact path="/Contact">
          <Contact />
        </Route>
        <Route exact path="/MyServices">
          <MyServices />
        </Route>
        <Route exact path="/WebsiteDesign">
          <WebsiteDesign />
        </Route>
        <Route exact path="/Marketing">
          <Marketing />
        </Route>
        <Route exact path="/Development">
          <Development />
        </Route> 
        <Route exact path="/SearchEngineOptimization">
          <SearchEngineOptimization />
        </Route>
        <Route exact path="/Ecommerce">
          <Ecommerce />
        </Route>
        <Route exact path="/Branding">
          <Branding />
        </Route>
        <Route exact path="/AndroidAppDevelopment">
          <AndroidAppDevelopment />
        </Route>
        <Route exact path="/BigcommerceWebsite">
          <BigcommerceWebsite />
        </Route>
        <Route exact path="/IOSAppDevelopment">
          <IOSAppDevelopment />
        </Route>
        <Route exact path="/MobileUIUX">
          <MobileUIUX />
        </Route>
        <Route exact path="/SearchEngineOptimization">
          <SearchEngineOptimization />
        </Route>
        <Route exact path="/ShopifyWebsite">
          <ShopifyWebsite />
        </Route>
        <Route exact path="/UIUXDesign">
          <UIUXDesign />
        </Route>
        <Route exact path="/WebsiteDesignCompany">
          <WebsiteDesignCompany />
        </Route>
        <Route exact path="/WebsiteHosting">
          <WebsiteHosting />
        </Route>
        <Route exact path="/WebsiteMaintenance">
          <WebsiteMaintenance />
        </Route>
        <Route exact path="/WordpressWebsite">
          <WordpressWebsite />
        </Route>
        <Route exact path="/PrivacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/Termsofuse">
          <Termsofuse />
        </Route>
        <Route component={Error}></Route>       
      </Switch>
      <Footer />
    </Router>
    </div>
  );
}

export default App;
