import React from 'react';
import AboutUs from "../assets/images/aboutus.jpg";
export default function About() {
    return (
        <>
            <div className="container aboutus">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>"We love what we do and we love helping others<br />succeed at what they love to do."</h2>
                        <p><b>About Us</b></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-7">
                        <p>Innovative, Creative, Professional & full service web designing, development, & digital marketing company.</p>
                        <p>Cloud e desk is a pioneering Digital Media Creation, Management, & Marketing agency specialized in delivering website designing, development, and digital marketing services.</p>
                        <p>Our team is geared to delivering More in every aspect of our business. More responsiveness, More Reliability, & More Results. We believe in excellence in execution of every task, whether it is a small assignment, or a large global campaign.</p>
                    <p>Our excellent services and at par dedication has made us the preferred choice for many clients in India. If you are looking for a professional website designing company in Delhi, you would be glad that you have chosen us. We have a rich portfolio of 300+ customers, many of whom stayed with us for more than 10 years and for whom we achieved business goals by using original ideas with proven practices on time and within the budget.</p>
                    <p>And we intend to deliver the same for YOU!</p>
                </div>
                <div className="col-sm-5"> <img src={AboutUs} alt="About Us" className="img-thumbnail" /></div>
                </div>
            </div>
        </>
    )
}
