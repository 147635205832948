import React from 'react';
import Marketing from "../assets/images/service_icon_1-1.png";
export default function Marketingwebsite() {
    return (
        <>
            <div className="container myservices">

                <div className="row">
                    <div className="col-sm-1"> <img src={Marketing} alt="Marketing" /></div>
                    <div className="col-sm-11">
                        <h3>Understanding Marketing Strategies</h3>
                        <p>A clear marketing strategy should revolve around the company's value proposition, which communicates to consumers what the company stands for, how it operates, and why it deserves their business.</p>
                        <p>This provides marketing teams with a template that should inform their initiatives across all of the company's products and services. For example, Cloud e desk is widely known as a discount retailer with “every day low prices,” whose business operations and marketing efforts are rooted in that idea.</p>
                    </div>

                </div>
            </div>
        </>
    )
}
