import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Marketing from "../assets/images/service_icon_1-1.png";
import Development from "../assets/images/service_icon_2-1.png";
import WebDesign from "../assets/images/service_icon_3-1.png";
import SEOOptimization from "../assets/images/service_icon_4-1.png";
import Ecommerce from "../assets/images/service_icon_5-1.png";
import Branding from "../assets/images/service_icon_6-1.png";
import WhyBg from "../assets/images/WhyBG.jpg";
import Understand from "../assets/images/process-icon01.png";
import Craft from "../assets/images/process-icon02.png";
import Launch from "../assets/images/process-icon05.png";
import Listen from "../assets/images/process-icon06.png";

export default function Home() {
    return (
        <>
           
            <div className="container myweb">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>We are the one of the most effective<br />Web Design Companies</h2>
                        <p>Getting online is easy. Succeeding online is a different story. You’ll need more than just a beautiful<br />website to stand out these days. Online marketing solutions. Conversion-based web design<br /> coupled with a lead generating marketing plan, your online success is inevitable.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="padding-50">
                            <Link to="/Marketing">
                                <img src={Marketing} alt="Marketing" />
                                <h3>Marketing</h3>
                                <p>We use strategic marketing tactics that have been proven to work.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="padding-50">
                            <Link to="/Development">
                                <img src={Development} alt="Development" />
                                <h3>Development</h3>
                                <p>Custom programming for most complex functions you can think.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="padding-50">
                            <Link to="/WebsiteDesign">
                                <img src={WebDesign} alt="Web Design" />
                                <h3>Web Design</h3>
                                <p>Pork chop pork belly hamburger prosciutto, fatback andouille flank.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="padding-50">
                            <Link to="/SearchEngineOptimization">
                                <img src={SEOOptimization} alt="SEO Optimization" />
                                <h3>SEO Optimization</h3>
                                <p>Optimizing our web designs to rank on the first page of google is our specialty.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="padding-50">
                            <Link to="/Ecommerce">
                                <img src={Ecommerce} alt="Ecommerce" />
                                <h3>Ecommerce</h3>
                                <p>We build your online store using a flexible, modular platform that allows</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="padding-50">
                            <Link to="/Branding">
                                <img src={Branding} alt="Branding" />
                                <h3>Branding</h3>
                                <p>A solid brand strategy, logo and guidelines help you to get You recognized.</p>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-12">
                    <Nav.Link><Link to="/MyServices">View All Services</Link></Nav.Link>
                    </div>
                   
                </div>
            </div>
            <div className="container-fluid whybg" style={{ backgroundImage: `url(${WhyBg})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Why cloud e desk?</h2>
                            <p>We are an innovatively led, strategically driven techno-friendly digital marketing company. We have created many robust brand experiences for our clients worldwide. Being one of the best digital marketing companies in the country, cloud e desk has been in the front line to provide expert online marketing solutions, right from web design and development to SEO services. Smartech has a team of qualified SEO experts that provides full-fledged SEO Services. Our zealous SEO professionals implement the most comprehensive strategies to boost the ranking of your website and build a brand value across the web!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid ccare">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="padding-50">
                                <img src={Understand} alt="Understand Business Goals" />
                                <h3>Understand Business Goals</h3>
                            </div>
                        </div>

                        <div className="col-sm-3">
                            <div className="padding-50">
                                <img src={Craft} alt="Craft a Strategy" />
                                <h3>Craft a Strategy</h3>

                            </div>
                        </div>

                        <div className="col-sm-3">
                            <div className="padding-50">
                                <img src={Launch} alt="Launch (Car out of the Garage)" />
                                <h3>Launch (Car out of the Garage)</h3>

                            </div>
                        </div>

                        <div className="col-sm-3">
                            <div className="padding-50">
                                <img src={Listen} alt="Listen & Improvise" />
                                <h3>Listen & Improvise</h3>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container-fluid talk">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h2>Let's talk about what we can build together</h2>
                            <p>Whatever may be your requirement - be it a simple website design, a complex data driven web application development, an ecommerce website, a native or cross platform mobile app development, a logo and brand identity design, a video production or a full fledged digital marketing campaign - we have a solution for you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
