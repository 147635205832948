import React from 'react';
import { Link } from 'react-router-dom';
export default function Error() {
    return (
        <>
            <div className="container myservices">

                <div className="row">                   
                    <div className="col-sm-12">
                        <h1>404</h1>
                        <p>Oops, the page you're looking for doesn't exist.</p>
                        <p>Please continue to our <Link to="/">Home page</Link>.</p>
                    </div>

                </div>
            </div>
        </>
    )
}
