import React from 'react';
import androidimg from "../assets/images/android-app-development.jpg";
export default function AndroidAppDevelopment() {
    return (
        <>
            <div className="container myservices">
                <div className="row">
                    <div className="col-sm-7">
                        <h3>Android App Development </h3>
                        <p>Android is undoubtedly an ingenious platform that offers various customization options to grow your business. Be it a small business or a huge one, every industry can have its own Android application that enables it to access a great number of people and boost businesses at a rapid rate. Top Android app development companies in India are offering services that follow the specifications of their clients and come up with mobile application development. They ensure to build a credible application that promises a high rate of ROI and optimum functionality. While the apps should be visually appealing, the performance part is highly important to serve the purpose of building an app.</p> </div>
                    <div className="col-sm-5"> <img src={androidimg} alt="android" className="img-thumbnail" /></div>
                </div>
            </div>
        </>
    )
}
