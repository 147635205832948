import React from 'react';
import iOSApp from "../assets/images/iOS-app-development.jpg";
export default function IOSAppDevelopment() {
    return (
        <>
            <div className="container myservices">
                <div className="row">
                    <div className="col-sm-7">
                        <h3>iOS App Development </h3>
                        <p>Usability is the most important factor that drives users to have a good app on the iPhone and iPad. Cloud e desk Interactive is a renowned iOS App development Company In Delhi NCR, India that specializes in creating powerful iPhone applications by using the latest technology. Our professionals help you with all types of app development for iOS that works well with all supported Apple devices. We are expert in designing and developing next-gen problem-solving solutions on iOS platforms for iPad, iPhone, iPod and Apple Watch devices. At our company, we ensure to deliver secure and scalable iOS applications for start-ups, enterprise clients, and SMEs worldwide.</p>
                    </div>
                    <div className="col-sm-5"> <img src={iOSApp} alt="iOSApp" className="img-thumbnail" /></div>
                </div>
            </div>
        </>
    )
}
